import { render, staticRenderFns } from "./edit.view.vue?vue&type=template&id=388e7dea&scoped=true&"
import script from "./edit.view.ts?vue&type=script&lang=ts&"
export * from "./edit.view.ts?vue&type=script&lang=ts&"
import style0 from "./edit.view.vue?vue&type=style&index=0&id=388e7dea&prod&scoped=true&lang=scss&"
import style1 from "./edit.view.vue?vue&type=style&index=1&id=388e7dea&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388e7dea",
  null
  
)

export default component.exports