import Vue from 'vue'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import moment from 'moment'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrSessionEditView',
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('event', ['event']),
    ...mapState('sessions', ['sessions', 'attendanceCounts']),
    ...mapState('attendee', ['attendees'])
  }
})

export default class GTRSessionEditView extends GtrSuper {
  data () {
    return {
      registration_types: {},
      moveSessionDialog: false,
      editScanTimeDialog: false,
      scanInTime: null,
      scanOutTime: null,
      overwriteExistingScanTimes: false,
      table: {
        search: '',
        items: [],
        registeredItems: [],
        unregisteredItems: [],
        selected: []
      },
      currentTab: 0,
      unselected: [],
      loading: false,
      // selectedParticipantUuids: [],
      participantScanHash: {},
      moveToNewSessionUuid: ''
    }
  }

  get tableHeaders () {
    return [
      { text: 'First Name', align: 'start', sortable: true, value: 'first_name', width: '150' },
      { text: 'Last Name', align: 'start', sortable: true, value: 'last_name', width: '200' },
      { text: 'Email', align: 'start', sortable: true, value: 'email', width: '150' },
      { text: 'Status', align: 'start', sortable: true, value: 'status', width: '200' },
      { text: 'Reg Type', align: 'start', sortable: true, value: 'registration_type', width: '200' },
      { text: 'Scan In Time', align: 'start', sortable: true, value: 'scan_in_time', width: '200' },
      { text: 'Scan Out Time', align: 'start', sortable: true, value: 'scan_out_time', width: '200' },
      { text: 'Time in Room', align: 'start', sortable: true, value: 'time_in_room', width: '200' }
    ]
  }

  tabChange (tab) {
    if (this.$data.currentTab !== tab) {
      this.$data.table.selected = []
    }
  }

  get eventTimezone (): string {
    return (this as any).event?.timezone
  }

  async mounted () {
    this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    await this.fetchSessions()
    if (!this.$store.state.event.eventAllContent) {
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
    }
    await this.fetchAttendees()
    await this.getSessionTrackingData()
  }

  get currentSession () {
    return ((this as any).sessions.find((session) => session.uuid === this.$route.params.session_uuid) || {})
  }

  private async fetchSessions () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  closeMoveToSessionDialog () {
    this.$data.moveSessionDialog = false
    this.$data.moveToNewSessionUuid = ''
  }

  closeEditScanTimeDialog () {
    this.$data.editScanTimeDialog = false
    this.$data.scanInTime = null
    this.$data.scanOutTime = null
  }

  get sessionUuidsForSelect (): string[] {
    const sessions = (this as any).sessions
    if (!sessions) {
      return []
    }
    const result: any = []
    for (let i = 0; i < sessions.length; i++) {
      if (sessions[i].uuid !== this.$route.params.session_uuid) {
        result.push({
          text: sessions[i].name,
          value: sessions[i].uuid
        })
      }
    }
    return result
  }

  private async getSessionTrackingData (): Promise<void> {
    try {
      this.$data.loading = true
      const { data } = await this.$store.dispatch('sessions/getSessionTrackingData', {
        event_uuid: this.$route.params.event_uuid,
        session_uuid: this.$route.params.session_uuid
      })
      this.mergeTrackingData(data.scan_data)
    } catch (err) {
      Container.get(Notification).error((err as Error).message)
    } finally {
      this.$data.loading = false
    }
  }

  private mergeTrackingData (data: Array<Record<string, any>>): void {
    const attendees = this.$data.table.items
    if (attendees.length === 0) {
      Container.get(Notification).warning('No attendees are signed up to this event')
      return
    }
    for (const attendee of attendees) {
      const attendeeScanData = data
        .filter(({ participant_short }) => participant_short.uuid === attendee.uuid)
        .sort()
      Vue.set(attendee, 'scan_in_time', null)
      Vue.set(attendee, 'scan_out_time', null)
      Vue.set(attendee, 'time_in_room', null)
      const scanDataLength = attendeeScanData.length
      if (attendeeScanData.length > 0) {
        attendee.scan_in_time = attendeeScanData[0].date_scanned
        attendee.scan_out_time = attendeeScanData.length > 1 ? attendeeScanData[scanDataLength - 1].date_scanned : null
        if (attendee.scan_in_time && attendee.scan_out_time) {
          attendee.time_in_room = moment(attendee.scan_out_time).diff(moment(attendee.scan_in_time), 'minutes')
        }
      }
    }
    this.$data.table.items = attendees
    this.$data.table.registeredItems = []
    this.$data.table.unregisteredItems = []

    /* If Scan Mode is set to Access Control, we need to add participants that have been given access to registeredItems */
    const attendeesWithSessionAccess = this.currentSession.session_access.filter((item) => item.access_level === '1' && item.participant_short).map((attendee) => attendee.participant_short.uuid)

    for (let i = 0; i < attendees.length; i++) {
      const attendee = attendees[i]
      const hasOverride = this.currentSession.session_access.filter((item) => item.access_level === '1' && item.override === 1 && item.participant_short && item.participant_short.uuid === attendee.uuid).length
      if (attendee.scan_in_time || (attendeesWithSessionAccess.includes(attendee.uuid) && (attendee.status === 'Complete' || hasOverride))) {
        this.$data.table.registeredItems.push(attendee)
      } else {
        this.$data.table.unregisteredItems.push(attendee)
      }
    }
  }

  async addToSession (): Promise<void> {
    try {
      this.$data.loading = true
      const uuids = this.$data.table.selected.map((attendee) => attendee.uuid)
      const payload = {
        session_uuid: this.$route.params.session_uuid,
        event_uuid: this.$route.params.event_uuid,
        data: { participant_uuids: uuids, device_id: 'Platform' }
      }
      await this.$store.dispatch('sessions/giveBulkAttendance', payload)
      await this.getSessionTrackingData()
      Container.get(Notification).success('Attendees added to session')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async addAccessToSession (): Promise<void> {
    try {
      this.$data.loading = true
      const uuids = this.$data.table.selected.map((attendee) => attendee.uuid)
      const payload = {
        session_uuid: this.$route.params.session_uuid,
        event_uuid: this.$route.params.event_uuid,
        data: { participant_uuids: uuids }
      }
      await this.$store.dispatch('sessions/giveBulkAccess', payload)
      await this.fetchSessions()
      await this.getSessionTrackingData()
      Container.get(Notification).success('Attendees granted access to session')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportIndividualSessionScans () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        session_uuid: this.$route.params.session_uuid
      }
      const response = await this.$store.dispatch('sessions/exportIndividualSessionScans', payload)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportUnregistredScans () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        session_uuid: this.$route.params.session_uuid
      }
      const response = await this.$store.dispatch('sessions/exportUnregistredScans', payload)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async editScanTime (): Promise<void> {
    try {
      this.$data.loading = true
      const uuids = this.$data.table.selected.map((attendee) => attendee.uuid)
      const response = await this.$store.dispatch('sessions/editScanTimeBulk', {
        event_uuid: this.$route.params.event_uuid,
        session_uuid: this.$route.params.session_uuid,
        data: {
          participant_uuids: uuids,
          device_id: 'Platform',
          scan_in: this.convertFromEventTimezoneToUtc(this.$data.scanInTime),
          scan_out: this.convertFromEventTimezoneToUtc(this.$data.scanOutTime),
          overwrite_existing_scans: this.$data.overwriteExistingScanTimes
        }
      })
      await this.getSessionTrackingData()
      Container.get(Notification).success(response.data.participants_updated_count + ' participants updated')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.editScanTimeDialog = false
      this.$data.scanInTime = null
      this.$data.scanOutTime = null
      this.$data.overwriteExistingScanTimes = false
      this.$data.loading = false
    }
  }

  async moveToSession (): Promise<void> {
    try {
      this.$data.loading = true
      const uuids = this.$data.table.selected.map((attendee) => attendee.uuid)
      await this.$store.dispatch('sessions/moveBulkAttendance', { event_uuid: this.$route.params.event_uuid, data: { original_session_uuid: this.currentSession.uuid, session_uuid: this.$data.moveToNewSessionUuid, participant_uuids: uuids } })
      await this.getSessionTrackingData()
      Container.get(Notification).success('Attendees moved to session')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.moveSessionDialog = false
      this.$data.loading = false
    }
  }

  async removeFromSession (): Promise<void> {
    try {
      this.$data.loading = true
      const uuids = this.$data.table.selected.map((attendee) => attendee.uuid)
      await this.$store.dispatch('sessions/removeBulkAttendance', { session_uuid: this.$route.params.session_uuid, event_uuid: this.$route.params.event_uuid, data: { participant_uuids: uuids } })
      if (this.currentSession.scan_modes === '1' || this.currentSession.scan_modes === '3') {
        await this.$store.dispatch('sessions/removeBulkAccess', { session_uuid: this.$route.params.session_uuid, event_uuid: this.$route.params.event_uuid, data: { participant_uuids: uuids } })
        await this.fetchSessions()
      }
      await this.getSessionTrackingData()
      Container.get(Notification).success('Attendees removed from session')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendees (): Promise<void> {
    try {
      const listViewFields = [{ field: 'first_name' }, { field: 'last_name' }, { field: 'email' }, { field: 'status' }, { field: 'registration_type' }]

      // Fetch the total number of records with a limit of 1
      const initialResponse = await this.$store.dispatch('attendee/fetchAttendeesListViewDirect', {
        event_uuid: this.$route.params.event_uuid,
        listViewFields,
        skip: 0,
        limit: 1,
        meta: 1
      })

      const totalResults = initialResponse.data.total
      const limit = 1000
      const totalPages = Math.ceil(totalResults / limit)

      // Create an array of Promises for fetching attendees in parallel
      const promises: Promise<any>[] = []
      const responses: any[] = []
      const participantsInOrder = {}
      for (let i = 0; i < totalPages; i++) {
        const promise = this.$store.dispatch('attendee/fetchAttendeesListViewDirect', {
          event_uuid: this.$route.params.event_uuid,
          listViewFields,
          skip: i * limit,
          limit
        })

        promises.push(promise)
        promise.then(response => {
          participantsInOrder[i] = response.data
        })
        responses.push(promise)
      }
      // Wait for all requests to settle
      await Promise.all(promises)
      const attendees: any = []
      for (let i = 0; i < totalPages; i++) {
        for (let p = 0; p < participantsInOrder[i].length; p++) {
          attendees.push(participantsInOrder[i][p])
        }
      }
      this.$store.commit('attendee/SET_ATTENDEES', attendees)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get speakerName (): string {
    if (!this.currentSession?.session_speakers || !this.currentSession.session_speakers.length) {
      return ''
    }
    const speaker = this.currentSession.session_speakers[0].speaker_condensed
    return `${speaker.first_name} ${speaker.last_name}`
  }

  @Watch('attendees', { immediate: true })
  onAttendeesChange (payload) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  @Watch('option_groups')
  onOptionsGroupsChange (optionGroups: any[]) {
    optionGroups.map(optionGroup => {
      if (optionGroup.name === 'Registration Types') {
        optionGroup.options.map(option => {
          this.$data.registration_types[option.uuid] = option.name
        })
      }
    })
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.SESSION_TRACKING.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Track Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }
}
